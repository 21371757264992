import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/all-home-version/Freelancer.vue"),
    meta: {
      title: "希革马人工智能",
    },
  },
  {
    path: "/user",
    name: "User",
    meta: {
      title: "用户中心",
    },
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/chat",
    name: "Chat",
    meta: {
      title: "宠物导师",
    },
    component: () => import("../views/Chat.vue"),
  },
  {
    path: "/freelancer",
    name: "Freelancer",
    meta: {
      title: "西希革马人工智能",
    },
    component: () => import("../views/all-home-version/Freelancer.vue"),
  },
  {
    path: "/blog-details",
    name: "BlogDetails",
    meta: {
      title: "文章详情",
    },
    component: () => import("../views/BlogDetails.vue"),
  },
  {
    path: "/manager",
    name: "Manager",
    meta: {
      title: "后台登录",
    },
    component: () => import("../views/manager/Login.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    meta: {
      title: "管理后台",
    },
    component: () => import("../views/manager/Admin.vue"),
    children: [
      {
        path: '', // 默认子路由，显示 Dashboard 页面
        meta: {
          title: "后台首页",
        },
        component: () => import("../views/manager/Welcome.vue"),
      },
      {
        path: "/userManager", // 用户管理页面路径
        name: "UserManager",
        meta: {
          title: "用户管理",
        },
        component: () => import("../views/manager/UserManager.vue"),
      },
      {
        path: "/bannerManager", // 用户管理页面路径
        name: "BannerManager",
        meta: {
          title: "轮播图管理",
        },
        component: () => import("../views/manager/BannerManager.vue"),
      },
      {
        path: "/articleManager", // 用户管理页面路径
        name: "ArticleManager", 
        meta: {
          title: "文章管理",
        },
        component: () => import("../views/manager/ArticleManager.vue"),
      },
      {
        path: "/sysManager", // 用户管理页面路径
        name: "SysManager", 
        meta: {
          title: "文章管理",
        },
        component: () => import("../views/manager/SysManager.vue"),
      },
    ],
  },
  {
    path: "*",
    component: Missing,
    meta: {
      title: "无此页 || 404",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});


export default router;
