<template>
  <div>
    <!-- Start Header Area -->
    <HeaderFive>
      <img slot="logo" src="../assets/img/xgm/icon.png" />
    </HeaderFive>
    <!-- End Header Area -->

    <!-- Start 404 Page  -->
    <div class=" error-page-inner bg_color--3">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title theme-gradient">404!</h1>
              <h3 class="sub-title">走到了奇怪的地方</h3>
              <span>你想要前往的网页并不存在.</span>
              <div class="error-button">
                <router-link to="/" class="rf-btn">返回主页</router-link>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End 404 Page  -->

    <FooterTwo />
  </div>
</template>

<script>
import HeaderFive from "../components/header/HeaderFive";
import FooterTwo from "../components/footer/FooterTwo";
export default {
  components: {
    HeaderFive,
    FooterTwo,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
