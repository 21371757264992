<template>
  <div id="home">
    <v-navigation-drawer class="hidden-md-and-up custom-navigation-drawer" v-model="drawer" fixed dark right temporary
      width="320">
      <v-list flat>
        <v-list-item :ripple="false" v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- End mobile menu sidebar item list -->
      </v-list>

      <div class="menu menu-toggle open" id="menu-toggle">
        <div class="menu-toggle-inner" @click="drawer = !drawer">
          <div class="menu-text-wrap">
            <span class="menu-text close-text">Close</span>
          </div>
          <div class="hamburger-wrap">
            <div id="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar class="header header-flat multi-page-route-appbar" height="116" flat>
      <router-link to="/" class="logo">
        <slot name="logo"></slot>
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- End mobile menu icon -->
      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto mainmenu-wrap">
        <v-btn v-for="item in items" :key="item.title" :to="item.to" link :ripple="false" text>{{ item.title }}</v-btn>

        <!-- <div class="purchase-btn">
          <a target="" href="/">
            返回主页
          </a>
        </div> -->
      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    items: [
      { title: "返回主页", to: "/" },
      // { title: "About", to: "/about-us" },
      // { title: "Service", to: "/service" },
      // { title: "Portfolio", to: "/portfolio" },
      // { title: "Awards", to: "/awards" },
      // { title: "Blog", to: "/blog" },
      // { title: "Contact", to: "/contact" },
    ],
    icon: "menu",
  }),
  methods: {},
};
</script>

<style lang="scss">
.multi-page-route-appbar {
  &.v-toolbar:not(.tab-toolbar) .v-btn {
    &.v-btn--active {
      color: #000000;
      font-weight: 500;
    }
  }
}
</style>
