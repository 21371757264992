<template>
  <div class="social-icon">
    <a v-for="(icon, i) in socialIconList" :key="i" :data-hover="icon.dataHover" :href="icon.url" target="_blank" style="width:60px">{{
      icon.name }} <span class="circle"></span></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      socialIconList: [
        {
          name: "OpenAi",
          dataHover: "Go",
          url: "https://openai.com/",
        },
        {
          name: "悠船",
          dataHover: "Go",
          url: "https://www.youchuanai.com/",
        },
        {
          name: "LibLib",
          dataHover: "Go",
          url: "https://www.liblib.art/?sourceId=000132",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
